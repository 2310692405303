import React, { useContext } from "react";
import { DrawerContext } from "../../contexts/DrawerContext";
import { Box, Toolbar, Typography } from "@mui/material";
import PieActiveArc from "../../component/Grafico/Grafico";
import CustomLabels from "../../component/Grafico/GraficoPalito";

const drawerWidth = 240;

const HomePage: React.FC = () => {
  const drawerContext = useContext(DrawerContext);

  if (!drawerContext) {
    throw new Error("DrawerContext must be usado dentro de um DrawerProvider");
  }

  const { open } = drawerContext;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 2,
        transition: "margin 0.3s ease",
        marginLeft: open ? `${drawerWidth}px` : 0,
        width: `calc(100% - ${open ? drawerWidth : 0}px)`,
        minHeight: "100vh",
      }}
    >
      <Toolbar />
      <Typography
        paragraph
        sx={{ fontWeight: "bold", fontSize: "2.15rem", ml: 2 }}
      >
        Dashbord
      </Typography>
      <Box
        sx={{ display: "flex", flexDirection: "row", width: "100%", gap: 2 }}
      >
        <Box sx={{ flexGrow: 2 }}>
          <CustomLabels />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <PieActiveArc />
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
