import React, { createContext, useState, ReactNode } from "react";

interface DrawerContextType {
  open: boolean;
  toggleDrawer: () => void;
}

export const DrawerContext = createContext<DrawerContextType | undefined>(
  undefined
);

export const DrawerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <DrawerContext.Provider value={{ open, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};
