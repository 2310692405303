import React, { useCallback, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Typography,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import api from "../../api/axiosConfig";
import { Data, SelectedOrder } from "../../types/PedidosTypes";

interface Column {
  id:
    | "referencia"
    | "quantidade"
    | "createdAt"
    | "cProd"
    | "acao"
    | "anexarNf"
    | "cnpj"
    | "status"
    | "cliente"
    | "canhoto"
    | "tempoNaEtapa";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "createdAt", label: "Data do Pedido", minWidth: 160 },
  { id: "referencia", label: "Referência", minWidth: 160 },
  { id: "cliente", label: "Destinatário", minWidth: 160 },
  { id: "status", label: "Etapa", minWidth: 160 },
  { id: "tempoNaEtapa", label: "Tempo na Etapa", minWidth: 160 },
  { id: "anexarNf", label: "Anexar NF", minWidth: 160 },
  { id: "canhoto", label: "Canhoto", minWidth: 160 },
  { id: "acao", label: "", minWidth: 100, align: "right" },
];

export default function StickyHeadTable() {
  const [openAnexarNf, setOpenAnexarNf] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Data | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<Data[]>([]);
  const [open, setOpen] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const [isLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<SelectedOrder[] | null>(
    null
  );

  const handleOpenAnexarNf = (row: Data) => {
    setSelectedRow(row);
    setOpenAnexarNf(true);
  };

  const calculateTempoNaEtapa = useCallback(
    (createdAt: Date, status: string): string => {
      if (status === "Incluído") {
        return "00:00:000";
      }

      const startTime = createdAt.getTime();

      if (isNaN(startTime)) {
        return "Invalid Date";
      }

      const diff = Date.now() - startTime;

      if (status === "Finalizado") {
        return formatTime(diff);
      }

      return formatTime(diff);
    },
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/cliente-pedido/quantidade");
        const mappedData = response.data.map((item: any) => {
          const createdAt = new Date(item.createdAt);
          return {
            id: item.id,
            referencia: item.referecinaPedido,
            cnpj: item.cnpj,
            cliente: item.cliente || "Sistema",
            quantidade: item.quantidade,
            createdAt: createdAt,
            cProd: item.cProd,
            status: item.status,
            tempoNaEtapa: calculateTempoNaEtapa(createdAt, item.status),
          };
        });
        setRows(mappedData);
      } catch (error) {
        console.error("Erro ao buscar os dados: ", error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      setRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          tempoNaEtapa: calculateTempoNaEtapa(
            new Date(row.createdAt),
            row.status
          ),
        }))
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [calculateTempoNaEtapa]);

  const formatTime = (diff: number): string => {
    const dias = Math.floor(diff / (1000 * 60 * 60 * 24));
    const horas = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutos = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const segundos = Math.floor((diff % (1000 * 60)) / 1000);
    const milissegundos = diff % 1000;

    return `${dias > 0 ? `${dias}d ` : ""}${horas
      .toString()
      .padStart(2, "0")}:${minutos.toString().padStart(2, "0")}:${segundos
      .toString()
      .padStart(2, "0")}:${milissegundos.toString().padStart(3, "0")}`;
  };

  const handleClickOpen = async (row: Data) => {
    try {
      const response = await api.get(
        `/cliente-pedido/pedidos?skip=0&take=10&referecinaPedido=${row.referencia}`
      );
      console.log(response);
      setSelectedOrder(response.data.pedidos);
      setOpen(true);
    } catch (error) {
      console.error("Erro ao buscar os detalhes: ", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      console.log("Arquivo selecionado:", file);
    } else {
      setSelectedFile(null);
      console.log("Nenhum arquivo selecionado");
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || !selectedRow) {
      console.error("Nenhum arquivo ou pedido selecionado");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("id", String(selectedRow.id));
    formData.append("referecinaPedido", selectedRow.referencia);

    try {
      const response = await api.post("/relatorio/upload-pdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Upload realizado com sucesso:", response.data);

      setOpenSnackbar(true);

      setOpenAnexarNf(false);
      setSelectedFile(null);
    } catch (error) {
      console.error("Erro ao realizar o upload:", error);
    }
  };

  const handleImgClick = async (row: Data) => {
    try {
      const response = await api.get(`/relatorio/${row.id}/canhoto`);
      if (response.data && response.data.imageBase64) {
        const imageBase64WithPrefix = `data:image/jpeg;base64,${response.data.imageBase64}`;
        setBase64Image(imageBase64WithPrefix);
        setOpenImageModal(true);
      } else {
        console.error("Imagem não encontrada ou em formato incorreto.");
      }
    } catch (error) {
      console.error("Erro ao buscar a imagem:", error);
    }
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
    setBase64Image("");
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id as keyof Data];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === "acao" ? (
                          <div style={{ display: "flex", gap: "8px" }}>
                            <Button
                              variant="contained"
                              onClick={() => handleClickOpen(row)}
                            >
                              Detalhes
                            </Button>
                          </div>
                        ) : column.id === "anexarNf" ? (
                          <Button
                            variant="text"
                            onClick={() => handleOpenAnexarNf(row)}
                            sx={{ borderColor: "black" }}
                          >
                            <FilePresentIcon style={{ fontSize: 25 }} />
                          </Button>
                        ) : column.id === "canhoto" ? (
                          <Button
                            variant="text"
                            onClick={() => handleImgClick(row)}
                            sx={{ borderColor: "black", color: "black" }}
                          >
                            <RemoveRedEyeTwoToneIcon style={{ fontSize: 25 }} />
                          </Button>
                        ) : column.id === "createdAt" ? (
                          <div>{row.createdAt.toLocaleDateString("pt-BR")}</div>
                        ) : column.id === "tempoNaEtapa" ? (
                          <div>{String(row.tempoNaEtapa)}</div>
                        ) : (
                          <div>{String(value)}</div>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Informações do Pedido</DialogTitle>
        <DialogContent>
          {selectedOrder &&
            selectedOrder.map((order: any) => (
              <Box key={order.id} sx={{ mb: 3 }}>
                <Typography>
                  <strong>Código do Produto:</strong> {order.cProd || "N/A"}
                </Typography>
                <Typography>
                  <strong>Data de Vencimento:</strong>{" "}
                  {new Date(order.vencimentoData).toLocaleDateString()}
                </Typography>
                <Typography>
                  <strong>Quantidade:</strong> {order.quantidade}
                </Typography>
                <Typography>
                  <strong>Descrição:</strong> {order.descricao}
                </Typography>
                <Typography>
                  <strong>CNPJ:</strong> {order.cnpj}
                </Typography>
                <Typography>
                  <strong>Transportadora:</strong>{" "}
                  {order.trasportadora || "N/A"}
                </Typography>
              </Box>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAnexarNf}
        onClose={() => {
          setOpenAnexarNf(false);
          setSelectedFile(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Anexar Nota Fiscal Para o Pedido</DialogTitle>
        <DialogContent sx={{ padding: "24px" }}>
          <Box
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "8px",
              padding: "16px",
              marginBottom: "24px",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Detalhes do Pedido
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography>
                  <strong>Referência:</strong>{" "}
                  {selectedRow?.referencia || "N/A"}
                </Typography>
                <Typography>
                  <strong>Cliente:</strong> {selectedRow?.cliente || "N/A"}
                </Typography>
                <Typography>
                  <strong>Status:</strong> {selectedRow?.status || "N/A"}
                </Typography>
                <Typography>
                  <strong>Quantidade:</strong>{" "}
                  {selectedRow?.quantidade || "N/A"}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "8px",
              padding: "16px",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Upload do Arquivo PDF
            </Typography>
            <label>
              <Button
                component="label"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  padding: "12px",
                  border: "2px dashed #e0e0e0",
                }}
              >
                <span className="ml-auto">Selecione um arquivo PDF</span>
                <input
                  type="file"
                  onChange={handleFileChange}
                  style={{ width: "0", height: "0", opacity: "0" }}
                />
              </Button>
            </label>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => {
              setOpenAnexarNf(false);
              setSelectedFile(null);
            }}
            variant="contained"
          >
            Fechar
          </Button>
          <Button
            onClick={handleUpload}
            variant="contained"
            color="primary"
            disabled={!selectedFile}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openImageModal}
        onClose={handleCloseImageModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Visualização do Canhoto</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <CircularProgress />
          ) : base64Image ? (
            <img
              src={base64Image}
              alt="Canhoto"
              style={{ width: "100%", height: "auto", maxHeight: "80vh" }}
              onError={(e) => {
                console.error("Erro ao carregar a imagem:", e);
              }}
            />
          ) : (
            <Typography>Carregando imagem...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageModal}>Fechar</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Anexo de Nota Fiscal enviado com sucesso!
        </Alert>
      </Snackbar>
    </Paper>
  );
}
