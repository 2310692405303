import React, { useState, useEffect, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import FiltroComponent from "../filter/filterPedido";
import api from "../../api/axiosConfig";
import {
  Column,
  Data,
  FilterValues,
  SelectedProduct,
} from "../../types/EstoqueTypes";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";

const columns: readonly Column[] = [
  { id: "checkbox", label: "", minWidth: 50 },
  { id: "cProd", label: "Código", minWidth: 100 },
  { id: "xProd", label: "Descrição", minWidth: 170 },
  {
    id: "qCom",
    label: "Quantidade",
    minWidth: 170,
    align: "right",
  },
  {
    id: "vencimentoData",
    label: "Data de Vencimento",
    minWidth: 170,
    align: "right",
    format: (value: string) => new Date(value).toLocaleDateString("pt-BR"),
  },
  {
    id: "inputValue",
    label: "Retirar QUA",
    minWidth: 170,
    align: "right",
  },
];

export default function StickyHeadTable() {
  const [skip, setSkip] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [busca, setBusca] = useState<string>("");
  const [selected, setSelected] = useState<SelectedProduct[]>([]);
  const [rows, setRows] = useState<Data[]>([]);
  const [count, setCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [validCart, setValidCart] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get(`/produtos/buscar-tudo`, {
        params: {
          take: rowsPerPage,
          skip: skip,
          busca: busca,
        },
      });

      const data = response.data;

      console.log("Data:", data);

      setCount(data.totalProdutos);

      const mappedData = data.produtos.map((item: any) => {
        const vencimentoData = item.produtoDetalhes[0]?.vencimentoData || "";
        return {
          id: item.id,
          xProd: item.xProd,
          cProd: item.cProd,
          qCom: item.qCom,
          size: item.size,
          vencimentoData: vencimentoData,
          inputValue: "",
          nfeId: item.nfeId,
          nfe: item.nfe,
        };
      });
      setRows(mappedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [rowsPerPage, skip, busca]);

  useEffect(() => {
    fetchData();
  }, [fetchData, skip, rowsPerPage, busca]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setSkip(newPage * rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setSkip(0);
  };

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    cProd: string
  ) => {
    const newSelected = [...selected];
    if (event.target.checked) {
      newSelected.push({ cProd, quantity: 0 });
    } else {
      const index = newSelected.findIndex((item) => item.cProd === cProd);
      newSelected.splice(index, 1);
    }
    setSelected(newSelected);
  };

  const handleInputChange = (cProd: string, value: string) => {
    const newSelected = [...selected];
    const index = newSelected.findIndex((item) => item.cProd === cProd);
    newSelected[index].quantity = parseInt(value, 10) || 0;
    setSelected(newSelected);
  };

  const checkIfSelectedItemsQuantityIsValid = () => {
    if (!selected.length || !rows?.length) return false;

    let isValid = true;

    const products = selected.map((item) =>
      rows.find((product) => product.cProd === item.cProd)
    );

    products.forEach((product, index) => {
      if (!product) {
        console.error(`Product with cProd ${selected[index].cProd} not found`);
        isValid = false;
        return;
      }

      if (selected[index].quantity <= 0) {
        console.error(
          `Product with cProd ${selected[index].cProd} has quantity lower than 0`
        );
        isValid = false;
        return;
      }

      if (selected[index].quantity > product.qCom) {
        console.error(
          `Product with cProd ${selected[index].cProd} has quantity higher than available quantity`
        );
        isValid = false;
        return;
      }
    });

    return isValid;
  };

  const handleSendOrder = async () => {
    if (!selected.length) return;

    try {
      const payload = selected.map((selectedRow) => {
        const product = rows.find((item) => item.cProd === selectedRow.cProd);

        const nfeId = product?.nfe?.id || product?.nfeId || "";

        return {
          referecinaPedido: "",
          referecinaCliente: "",
          trasportadora: "",
          detId: product?.id,
          quantidade: selectedRow.quantity,
          descricao: product?.xProd,
          cnpj: product?.cnpj || "17069905000110",
          empresaId: Number(1),
          nfeId: nfeId,
        };
      });

      console.log("Payload:", payload);

      await api.post("/cliente-pedido", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Pedido enviado com sucesso:", payload);
      setDialogOpen(false);
      setSuccessDialogOpen(true);
    } catch (error) {
      console.error("Erro ao enviar o pedido", error);
    }
  };

  const openOrderDialog = () => {
    if (!checkIfSelectedItemsQuantityIsValid()) {
      setValidCart(false);
    } else {
      setValidCart(true);
    }
    setDialogOpen(true);
  };

  const showQuantity = ({
    selectedQuantity,
    availableQuantity,
  }: {
    selectedQuantity: number;
    availableQuantity: number;
  }) => {
    const quantityHigherThanZero = selectedQuantity > 0;
    const quantityLowerOrEqualThanAvailable =
      selectedQuantity <= availableQuantity;

    if (!quantityHigherThanZero)
      return (
        <span style={{ color: "red" }}>
          Selecione a quantidade na coluna "Retirar QUA"
        </span>
      );
    if (!quantityLowerOrEqualThanAvailable)
      return (
        <span style={{ color: "red" }}>Quantidade maior que a disponível</span>
      );
    return selectedQuantity;
  };

  const handleFilter = async ({ busca }: FilterValues) => {
    setBusca(busca);
  };

  const handleResetFilter = () => {
    setBusca("");
  };

  return (
    <Box className="w-full flex flex-col gap-8">
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Finalizar pedido</DialogTitle>
        <DialogContent>
          {!selected.length ? (
            <p>Selecione pelo menos um produto para finalizar o pedido.</p>
          ) : (
            selected.map((elm) => {
              const row = rows.find((row) => row.cProd === elm.cProd);
              if (!row) return null;
              return (
                <Box key={row.cProd} sx={{ mb: 3 }}>
                  <Typography>
                    <strong>Código do Produto: </strong>
                    {row.cProd || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Descrição: </strong> {row.xProd}
                  </Typography>
                  <Typography>
                    <strong>Data de Vencimento: </strong>
                    {row.vencimentoData
                      ? new Date(row.vencimentoData).toLocaleDateString()
                      : "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Quantidade: </strong>
                    {showQuantity({
                      selectedQuantity: elm.quantity,
                      availableQuantity: row.qCom,
                    })}
                  </Typography>
                </Box>
              );
            })
          )}
        </DialogContent>
        <DialogActions>
          {validCart && (
            <Button onClick={() => handleSendOrder()}>Finalizar Pedido</Button>
          )}
          <Button onClick={() => setDialogOpen(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <GridCheckCircleIcon sx={{ color: green[500], fontSize: 40 }} />
          <Typography variant="h6">Pedido Enviado</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>O pedido foi enviado com sucesso!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessDialogOpen(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>

      <FiltroComponent onFilter={handleFilter} onReset={handleResetFilter} />
      <Paper className="w-full">
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const isItemSelected = !!selected.find(
                  (item) => item.cProd === row.cProd
                );
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell padding="checkbox">
                      <input
                        type="checkbox"
                        checked={isItemSelected}
                        onChange={(event) => handleSelect(event, row.cProd)}
                        style={{
                          transform: "scale(1.5)",
                          width: 15,
                          height: 15,
                          margin: 15,
                        }}
                      />
                    </TableCell>
                    {columns.map((column) => {
                      if (column.id === "checkbox") return null;
                      const value = row[column.id as keyof Data];
                      const quantity = selected.find(
                        (item) => item.cProd === row.cProd
                      )?.quantity;
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "inputValue" ? (
                            <input
                              type="number"
                              max={row.qCom}
                              value={quantity || ""}
                              onChange={(e) =>
                                handleInputChange(row.cProd, e.target.value)
                              }
                              disabled={!isItemSelected}
                              style={{
                                border: "1px solid #000",
                                padding: "1px",
                                margin: "10px",
                                width: "20%",
                                borderRadius: "4px",
                                backgroundColor: isItemSelected
                                  ? "#fff"
                                  : "#f0f0f0",
                              }}
                            />
                          ) : column.format ? (
                            column.format(value)
                          ) : typeof value === "string" ||
                            typeof value === "number" ? (
                            value
                          ) : (
                            JSON.stringify(value) || ""
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count || 0}
          rowsPerPage={rowsPerPage}
          page={skip / rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Box className="flex justify-end gap-4">
        <Button variant="contained" color="primary" onClick={openOrderDialog}>
          Inserir Pedido
        </Button>
      </Box>
    </Box>
  );
}
