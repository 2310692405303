import React, { useContext } from "react";
import { DrawerContext } from "../../contexts/DrawerContext";
import { Box, Toolbar, Typography } from "@mui/material";

const drawerWidth = 240;
const miniDrawerWidth = 60;

const AboutPage: React.FC = () => {
  const drawerContext = useContext(DrawerContext);

  if (!drawerContext) {
    throw new Error("DrawerContext must be used within a DrawerProvider");
  }

  const { open } = drawerContext;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        transition: "margin 0.3s ease",
        marginLeft: open ? `${drawerWidth}px` : `${miniDrawerWidth}px`,
      }}
    >
      <Toolbar />
      <Typography paragraph>Meu Projeto</Typography>
      <Typography paragraph>about page.</Typography>
    </Box>
  );
};

export default AboutPage;
