import React, { useState } from "react";
import { Button, Box, Snackbar, Alert } from "@mui/material";
import api from "../../api/axiosConfig";

interface FileUploadButtonProps {
  onFileSelect: (file: File | null) => void;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  onFileSelect,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file =
      event.target.files && event.target.files.length > 0
        ? event.target.files[0]
        : null;
    if (file) {
      setSelectedFile(file);
      onFileSelect(file);
    }
    event.target.value = "";
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await api.post("/xml-nfe/xml-pedidos", formData, {
        headers: {
          "Content-Type": "multipart",
        },
      });

      if (response.data) {
        const result = response.data;
        console.log("Upload bem-sucedido:", result);
        setOpenSnackbar(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        console.error("Falha no upload:", response.statusText);
      }
    } catch (error) {
      console.error("Erro no upload:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <input
          accept=".xml"
          style={{ display: "none" }}
          id="file-upload"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="file-upload">
          <Button variant="contained" color="success" component="span">
            {selectedFile ? selectedFile.name : "Importar XML"}
          </Button>
        </label>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!selectedFile}
          style={{ marginLeft: "10px" }}
        >
          Enviar
        </Button>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Pedido enviado com sucesso!
        </Alert>
      </Snackbar>
    </>
  );
};

export default FileUploadButton;
