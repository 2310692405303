import React, { useContext } from "react";
import { DrawerContext } from "../../contexts/DrawerContext";
import { Box, Toolbar, Typography } from "@mui/material";
import StickyHeadTable from "../../component/Table/TablePedidos";

const drawerWidth = 240;
const PrdidosPage: React.FC = () => {
  const drawerContext = useContext(DrawerContext);

  if (!drawerContext) {
    throw new Error("DrawerContext must be used within a DrawerProvider");
  }

  const { open } = drawerContext;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 5,
        p: 5,
        transition: "margin 0.3s ease",
        marginLeft: open ? `${drawerWidth}px` : `0px`,
      }}
    >
      <Toolbar />
      <Typography paragraph sx={{ fontWeight: "bold", fontSize: "2.15rem" }}>
        Pedidos
      </Typography>
      <StickyHeadTable />
    </Box>
  );
};

export default PrdidosPage;
