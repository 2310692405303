import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  IconButton,
  Box,
  Collapse,
  DialogTitle,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FileUploadButton from "../Upload/UploadPedido";
import { FiltroComponentProps } from "../../types/filterType";

const FiltroComponent: React.FC<FiltroComponentProps> = ({
  onFilter,
  onReset,
}) => {
  const [busca, setBusca] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const handleFilterClick = () => {
    onFilter({ busca });
  };

  const handleResetClick = () => {
    setBusca("");
    onReset();
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Box mb={2}>
      <FileUploadButton onFileSelect={(file: File | null) => {}} />
      <IconButton onClick={toggleOpen}>
        <DialogTitle>Filtrar</DialogTitle>
        <FilterListIcon />
      </IconButton>
      <Collapse in={open} timeout="auto">
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Buscar por código ou descrição"
              variant="filled"
              value={busca}
              onChange={(e) => setBusca(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFilterClick}
            >
              Filtrar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleResetClick}
            >
              Resetar
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

export default FiltroComponent;
