import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import * as XLSX from "xlsx";
import api from "../../api/axiosConfig";

interface ChartData {
  id: number;
  value: number;
  label: string;
}

const customColors = ["#1f77b4", "#ff7f0e", "#9467bd", "#2ca02c", "#d62728"];

export default function PieActiveArc() {
  const [data, setData] = useState<ChartData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/cliente-pedido/status");
        const pedidosData = response.data;

        const chartData: ChartData[] = [
          {
            id: 0,
            value: pedidosData.pedidosIncluido,
            label: "Solicitação de pedido",
          },
          {
            id: 1,
            value: pedidosData.pedidosSeparacao,
            label: "Pedidos em Separação",
          },
          {
            id: 2,
            value: pedidosData.pedidosConferencia,
            label: "Conferência de Pedidos",
          },
          {
            id: 3,
            value: pedidosData.pedidosFinalizado,
            label: "Pedidos Finalizados",
          },
          {
            id: 4,
            value: pedidosData.pedidosPendentes,
            label: "Pedidos Pendente",
          },
        ];

        setData(chartData);
      } catch (error) {
        console.error("Erro ao buscar os dados do status dos pedidos:", error);
      }
    };

    fetchData();
  }, []);

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Relatório");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(dataBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Relatorio-Pedido.xlsx";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Card sx={{ width: "100%", maxWidth: "950px", height: "auto" }}>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 2, ml: 2, fontWeight: "bold" }}>
          Relatório de Pedidos
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <PieChart
            series={[
              {
                data,
                highlightScope: { faded: "global", highlighted: "item" },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "gray",
                },
                innerRadius: 30,
                outerRadius: 100,
                paddingAngle: 2,
                cornerRadius: 5,
              },
            ]}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
            colors={customColors}
            width={400}
            height={200}
          />
          <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
            {data.map((item, index) => (
              <Box
                key={item.id}
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: customColors[index % customColors.length],
                    mr: 1,
                  }}
                />
                <Typography variant="body2">{item.label}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Button
          variant="contained"
          color="success"
          sx={{ mt: 2, ml: 2 }}
          onClick={handleDownload}
        >
          Download Por Excel
        </Button>
      </CardContent>
    </Card>
  );
}
