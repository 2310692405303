import React, { useContext } from "react";
import { DrawerContext } from "../../contexts/DrawerContext";
import { Box, Toolbar, Typography } from "@mui/material";
import StickyHeadTable from "../../component/Estoque/TableEstoque";

const drawerWidth = 240;
const miniDrawerWidth = 60;

const EstoqueTable: React.FC = () => {
  const drawerContext = useContext(DrawerContext);

  if (!drawerContext) {
    throw new Error("DrawerContext must be used within a DrawerProvider");
  }

  const { open } = drawerContext;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        transition: "margin 0.3s ease",
        marginLeft: open ? `${drawerWidth}px` : `${miniDrawerWidth}px`,
      }}
    >
      <Toolbar />
      <Box className="flex ">
        <Typography paragraph sx={{ fontWeight: "bold", fontSize: "2.15rem" }}>
          Controle de Estoque
        </Typography>
      </Box>
      <Typography>
        Tela para criação de novos pedidos. Você poderá criar pedidos importando
        um arquivo, XML da NF-e, ou selecionando manualmente os itens e suas
        quantidades.
      </Typography>
      <StickyHeadTable />
    </Box>
  );
};

export default EstoqueTable;
