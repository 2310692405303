import {
  Button,
  Card,
  CardContent,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import * as XLSX from "xlsx";
import { useState, useEffect } from "react";
import api from "../../api/axiosConfig";

interface SummaryItem {
  lote: string;
  validade: string;
  descricao: string;
  total_qCom: number;
  quantidade: number;
}

export default function CustomLabels() {
  const [data, setData] = useState<SummaryItem[]>([]);
  const [selectedLote, setSelectedLote] = useState<string>("Lote");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/relatorio/summary");
        setData(response.data.detalhes);
      } catch (error) {
        console.error("Error fetching data from API", error);
      }
    };

    fetchData();
  }, []);

  const handleLoteChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value === "" ? "Lote" : event.target.value;
    setSelectedLote(value);
  };

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Relatório");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    const url = window.URL.createObjectURL(dataBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Relatorio-Pedido.xlsx";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const selectedLoteData =
    selectedLote === "Lote" ? null : data.find((d) => d.lote === selectedLote);

  return (
    <Card style={{ width: "100%", maxWidth: "1000px", margin: "auto" }}>
      <Typography variant="h6" sx={{ mb: 2, ml: 2, fontWeight: "bold" }}>
        Relatório de Armazenagem por Lote
      </Typography>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <FormControl sx={{ minWidth: 120, mr: 2 }}>
            <InputLabel id="lote-select-label">Lote</InputLabel>
            <Select
              labelId="lote-select-label"
              id="lote-select"
              value={selectedLote === "Lote" ? "" : selectedLote}
              onChange={handleLoteChange}
              label="Lote"
            >
              <MenuItem value="">
                <em>Lote</em>
              </MenuItem>
              {data.map((d) => (
                <MenuItem key={d.lote} value={d.lote}>
                  {d.lote}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body1"
            sx={{
              writingMode: "vertical-rl",
              transform: "rotate(-180deg)",
              mr: 2,
            }}
          >
            Valores
          </Typography>
          <BarChart
            series={[
              {
                data: data.map((d) => d.total_qCom),
                label: "Total",
                color: "#90caf9",
              },
              {
                data: data.map((d) => d.quantidade),
                label: "Quantidade",
                color: "#ef9a9a",
              },
              ...(selectedLote !== "Lote"
                ? [
                    {
                      data: data.map((d) =>
                        d.lote === selectedLote ? d.total_qCom : 0
                      ),
                      label: `Total (${selectedLote})`,
                      color: "#1976d2",
                    },
                    {
                      data: data.map((d) =>
                        d.lote === selectedLote ? d.quantidade : 0
                      ),
                      label: `Quantidade (${selectedLote})`,
                      color: "#d32f2f",
                    },
                  ]
                : []),
            ]}
            xAxis={[
              {
                scaleType: "band",
                data: data.map((d) => d.lote),
              },
            ]}
            width={800}
            height={450}
          />
        </Box>
        {selectedLoteData && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Detalhes do Lote Selecionado:</Typography>
            <Typography variant="body1">
              <strong>Lote:</strong> {selectedLoteData.lote}
            </Typography>
            <Typography variant="body1">
              <strong>Validade:</strong> {selectedLoteData.validade}
            </Typography>
            <Typography variant="body1">
              <strong>Descrição:</strong> {selectedLoteData.descricao}
            </Typography>
            <Typography variant="body1">
              <strong>Total:</strong> {selectedLoteData.total_qCom}
            </Typography>
            <Typography variant="body1">
              <strong>Quantidade:</strong> {selectedLoteData.quantidade}
            </Typography>
          </Box>
        )}
        <Button
          variant="contained"
          color="success"
          style={{ marginTop: "16px", marginLeft: "16px" }}
          onClick={handleDownload}
        >
          Download Por Excel
        </Button>
      </CardContent>
    </Card>
  );
}
